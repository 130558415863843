import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Card = ({ 
    slug,
    title, 
    photo_main, 
    address, 
    city, 
    state, 
}) => {
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className='card'>
            <h3 className='card__title'>{title}</h3>
            <div className='card__header'>
                <img className='card__header__photo' src={photo_main} alt='House' />
            </div>
            <p className='card__location'>{address}, {city}, {state}</p>
            {/* <div className='row'>
                <div className='col-2-of-3'>
                    <p className='card__info'>Price: ${numberWithCommas(price)}</p>
                    <p className='card__info'>Bedrooms: {bedrooms}</p>
                    <p className='card__info'>Bathrooms: {bathrooms}</p>
                </div>
                <div className='col-1-of-3'>
                    <p className='card__saletype'>{sale_type}</p>
                    <p className='card__hometype'>{home_type}</p>
                    <p className='card__sqft'>Sqft: {sqft}</p>
                </div>
            </div> */}
            <Link className='card__link' to={`/listings/${slug}`}>View Listing</Link>
        </div>
    );
};

Card.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    photo_main: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    acres: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    
    property_type: PropTypes.string.isRequired,
   
};

export default Card;
