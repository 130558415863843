import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axiosInstance from '../axiosConfig';  // Import the centralized Axios instance
import Card from '../components/Card';
import Pagination from '../components/Pagination';

const Listings = () => {
    const [listings, setListings] = useState([]);
    const [count, setCount] = useState(0);
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    const [active, setActive] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axiosInstance.get('/api/listings/?page=1');
                console.log('API Response:', res.data); // Log the entire API response

                if (res.data.results) {
                    setListings(res.data.results);
                } else {
                    setListings(res.data); // Adjust based on actual structure
                }

                setCount(res.data.count || 0);
                setPrevious(res.data.previous || '');
                setNext(res.data.next || '');
            } catch (err) {
                console.error('Error fetching listings:', err);
            }
        };

        fetchData();
    }, []);

    const displayListings = () => {
        if (!listings || listings.length === 0) {
            return <p>No listings available</p>;
        }

        let display = [];
        let result = [];

        listings.forEach((listing, index) => {
            display.push(
                <Card
                    key={index}
                    title={listing.title}
                    address={listing.address}
                    city={listing.city}
                    state={listing.state}
                    price={listing.price}
                    sale_type={listing.sale_type}
                    property_type={listing.property_type}
                    acres={listing.acres}
                    photo_main={listing.photo_main}
                    slug={listing.slug}
                    land_type={listing.land_type}
                />
            );
        });

        for (let i = 0; i < listings.length; i += 3) {
            result.push(
                <div key={i} className='row'>
                    <div className='col-1-of-3'>
                        {display[i]}
                    </div>
                    <div className='col-1-of-3'>
                        {display[i + 1] ? display[i + 1] : null}
                    </div>
                    <div className='col-1-of-3'>
                        {display[i + 2] ? display[i + 2] : null}
                    </div>
                </div>
            );
        }

        return result;
    };

    const visitPage = async (page) => {
        try {
            const res = await axiosInstance.get(`/api/listings/?page=${page}`);
            if (res.data.results) {
                setListings(res.data.results);
            } else {
                setListings(res.data); // Adjust based on actual structure
            }
            setPrevious(res.data.previous || '');
            setNext(res.data.next || '');
            setActive(page);
        } catch (err) {
            console.error('Error fetching page:', err);
        }
    };

    const previous_number = async () => {
        if (previous) {
            try {
                const res = await axiosInstance.get(previous);
                if (res.data.results) {
                    setListings(res.data.results);
                } else {
                    setListings(res.data); // Adjust based on actual structure
                }
                setPrevious(res.data.previous || '');
                setNext(res.data.next || '');
                setActive(active - 1);
            } catch (err) {
                console.error('Error fetching previous page:', err);
            }
        }
    };

    const next_number = async () => {
        if (next) {
            try {
                const res = await axiosInstance.get(next);
                if (res.data.results) {
                    setListings(res.data.results);
                } else {
                    setListings(res.data); // Adjust based on actual structure
                }
                setPrevious(res.data.previous || '');
                setNext(res.data.next || '');
                setActive(active + 1);
            } catch (err) {
                console.error('Error fetching next page:', err);
            }
        }
    };

    return (
        <main className='listings'>
            <Helmet>
                <title>Realest Estate - Listings</title>
                <meta name='description' content='Listings page' />
            </Helmet>
            <section className='listings__listings'>
                {displayListings()}
            </section>
            <section className='listings__pagination'>
                <div className='row'>
                    <Pagination
                        itemsPerPage={3}
                        count={count}
                        visitPage={visitPage}
                        previous={previous_number}
                        next={next_number}
                        active={active}
                        setActive={setActive}
                    />
                </div>
            </section>
        </main>
    );
};

export default Listings;
