import React from 'react';
import { Link } from 'react-router-dom';
import about from '../assets/images/about2.jpg';
import company from '../assets/images/company.jpg'

const Company = () => {
  return (
    <div>
      {/* First Section */}
      <div className="company-section row">
        <div className="company-content col-lg-6">
          <h4 className="company-heading">About Off Grid Developments</h4>
          <h1 className="company-title">Get to know us</h1>
          <p className="company-description">
            Off Grid Developments LLC has been a trusted name in land investment for over a decade. With a solid foundation built on integrity, expertise, and a deep understanding of the
            real estate market. We offer premium land parcels that promise both immediate value and long-term growth. When you invest in land 
            with us, you're not just buying property — you're securing a piece of the future. Let our decade of experience guide you to a successful
            and rewarding experience. 
          </p>
          <Link to="/listings" className="signup-button">Our Properties</Link>
        </div>
        <div className="company-image col-lg-6">
          <img src={about} alt="Company" />
        </div>
      </div>

      {/* Second Section */}
      <div className="company-future-section row">
       
        <div className="future-content- col-lg-6">
          {/* <h2 className="future-title">This is the future of real estate investment</h2> */}
          <p className="future-description">
            Our company was founded by two brothers, Christopher and Marvin born and raised in southern Georgia. Growing up surrounded by the rich farmlands and vast wilderness
            of the South, they developed a deep passion for farming, fishing, hunting, and the great outdooors. With a vision to help others experience the joys of land ownership
            and the benefits of investing in nature, the brothers combined their expertise, love for wildlife, and commitment to preserving the environment to create a business 
            that not only offers valuable land opportunities but also promotes sustainable and responsible land use.
          </p>
        </div>
        {/* <div className="quote-section col-lg-6">
          <div className="quote-circle">
            <p className="quote-text">"Find your land build your future"</p>
            <p className="quote-author">- Off Grid Developments LCC</p>
          </div>
        </div> */}
        <div className="company-images col-lg-6">
          <img src={company} alt="Company" />
        </div>

      </div>
    </div>
    
  );
};

export default Company;
