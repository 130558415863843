import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './containers/Home';
import Company from './containers/Company';
import About from './containers/About';
import Contact from './containers/Contact';
import Listings from './containers/Listings';
import ListingDetail from './containers/ListingDetail';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import NotFound from './components/NotFound';
import Layout from './hocs/Layout';
import PrivateRoute from './components/privateRoute';
import Success from './components/Success';
import Cancel from './components/Cancel';
import ForgotPassword from './containers/ForgotPassword';  
import ResetPassword from './containers/ResetPassword'; 
import { Provider } from 'react-redux';
import store from './store';
import './sass/main.scss';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap JS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const App = () => (
    <Provider store={store}>
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<About />} />
                    <Route path="/company" element={<Company />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/listings" element={<Listings />} />
                    <Route path="/success" element={<Success />} /> 
                    <Route path="/cancel" element={<Cancel />} /> 
                    <Route
                        path="/listings/:id"
                        element={
                            <PrivateRoute>
                                <ListingDetail />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />  
                    <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />  
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Layout>
        </Router>
    </Provider>
);

export default App;
